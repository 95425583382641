@keyframes glowing {
    0% {
      background-color: #2ba805;
      box-shadow: 0 0 5px #2ba805;
    }
    50% {
      background-color: #49e819;
      box-shadow: 0 0 20px #49e819;
    }
    100% {
      background-color: #2ba805;
      box-shadow: 0 0 5px #2ba805;
    }
}
@media all and (min-width: 992px) {
	.navbar .nav-item .dropdown-menu{ display: none; }
	.navbar .nav-item:hover .nav-link{   }
	.navbar .nav-item:hover .dropdown-menu{ display: block; }
	.navbar .nav-item .dropdown-menu{ margin-top:0; }
}

/* @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;600&display=swap");
body section{
  font-family: "Oswald", sans-serif;
  background-color: #212121;
}
body section {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}
body section .row1 {
  align-items: center;
  height: 100vh;
}

.card1 {
  position: relative;
  height: 400px;
  width: 100%;
  margin: 10px 0;
  transition: ease all 2.3s;
  perspective: 1200px;
}
.card1:hover .cover {
  transform: rotateX(0deg) rotateY(-180deg);
}
.card1:hover .cover:before {
  transform: translateZ(30px);
}
.card1:hover .cover:after {
  background-color: black;
}
.card1:hover .cover h1 {
  transform: translateZ(100px);
}
.card1:hover .cover .price {
  transform: translateZ(60px);
}
.card1:hover .cover a {
  transform: translateZ(-60px) rotatey(-180deg);
}
.card1 .cover {
  position: absolute;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  transition: ease all 2.3s;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.card1 .cover:before {
  content: "";
  position: absolute;
  border: 5px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  transition: ease all 2.3s;
  transform-style: preserve-3d;
  transform: translateZ(0px);
}
.card1 .cover:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2;
  transition: ease all 1.3s;
  background: rgba(0, 0, 0, 0.4);
}
.card1 .cover.item-a {
  background-image: url("https://images.unsplash.com/photo-1520412099551-62b6bafeb5bb?auto=format&fit=crop&w=600&q=80");
}
.card1 .cover.item-b {
  background-image: url("https://images.unsplash.com/photo-1497250681960-ef046c08a56e?auto=format&fit=crop&w=600&q=80");
}
.card1 .cover.item-c {
  background-image: url("https://images.unsplash.com/photo-1525945518069-b924046d1385?auto=format&fit=crop&w=600&q=80");
}
.card1 .cover h1 {
  font-weight: 600;
  position: absolute;
  bottom: 55px;
  left: 50px;
  color: white;
  transform-style: preserve-3d;
  transition: ease all 2.3s;
  z-index: 3;
  font-size: 3em;
  transform: translateZ(0px);
}
.card1 .cover .price {
  font-weight: 200;
  position: absolute;
  top: 55px;
  right: 50px;
  color: white;
  transform-style: preserve-3d;
  transition: ease all 2.3s;
  z-index: 4;
  font-size: 2em;
  transform: translateZ(0px);
}
.card1 .card-back {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #0b0f08;
  transform-style: preserve-3d;
  transition: ease all 2.3s;
  transform: translateZ(-1px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.card1 .card-back a {
  transform-style: preserve-3d;
  transition: ease transform 2.3s, ease background 0.5s;
  transform: translateZ(-1px) rotatey(-180deg);
  background: transparent;
  border: 1px solid white;
  font-weight: 200;
  font-size: 1.3em;
  color: white;
  padding: 14px 32px;
  outline: none;
  text-decoration: none;
}
.card1 .card-back a:hover {
  background-color: white;
  color: #0b0f08;
} */